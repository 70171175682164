import { ComponentProps, FunctionComponent } from "react";
import { BiospecimenIcon, DataOverview, GenomicsIcon, TissueSampleIcon } from "~/assets/icons";

enum Report {
  OVERVIEW = "overview",
  BIOSPECIMEN = "biospecimen",
  TISSUE = "tissue",
  BLOOD = "blood",
  GENOMICS = "genomics",
}

interface ReportDisplay {
  ids: string[];
  label: string;
  description: string;
  icon: FunctionComponent<ComponentProps<"svg">>;
  iconColor: string;
}

export const DISPLAY_REPORT: Record<Report, ReportDisplay> = {
  [Report.OVERVIEW]: {
    ids: [
      "Overview-Report-2z3oTQpFRxQ9Uv6XbEpwJJ",
      "Overview-Report-12tSRUxf6QMjDW7Ldak26U/page/snJelUA8R4",
      "Overview-Report-Manifold-stage-4NDqcCyDWRr0aEZaSm0d37/page/snJelUA8R4",
      "Overview-Report-Manifold-3Yf7v1WHhbCPjtIY4TFrDn/page/snJelUA8R4",
    ],
    label: "Overview",
    description: "Start here to get an overview of data in the platform",
    icon: DataOverview,
    iconColor: "#2225F5",
  },
  [Report.BIOSPECIMEN]: {
    ids: [
      "Biospecimen-Report-Manifold-Stage-eEzqADdqn6d5XZDQG340B/page/H6lB7taSnr",
      "Biospecimen-Report-Manifold-12BYMpGavntYA2Zx0f8zE8/page/H6lB7taSnr",
    ],
    label: "Biospecimen Availability",
    description: "Overview of available specimens",
    icon: BiospecimenIcon,
    iconColor: "#E14B4B",
  },
  [Report.TISSUE]: {
    ids: [
      "Tissue-Biospecimen-Report-5lrDQSwvlsniLhbAL8i3Gi/page/H6lB7taSnr",
      "Tissue-Biospecimens-6rsuqelk2578C3RgImZEZl/page/H6lB7taSnr",
    ],
    label: "Tissue and Bone Marrow Availability",
    description: "Overview of solid specimens",
    icon: TissueSampleIcon,
    iconColor: "#FF4C1A",
  },
  [Report.BLOOD]: {
    ids: [
      "Blood-Biospecimen-Report-64hyi9zbwmLFpDIsZwbcxw/page/NJc0bpb_sT",
      "Blood-Biospecimens-4o35LzJyGVnHxLlaXQIlvh/page/NJc0bpb_sT",
    ],
    label: "Blood Availability",
    description: "Overview of blood specimens",
    icon: BiospecimenIcon,
    iconColor: "#E14B4B",
  },
  [Report.GENOMICS]: {
    ids: [
      "Genomics-Report-1Q2Lz0rQH0OuL09MpGuWwG/page/ApZslS2VUx",
      "Genomics-Report-DKS1yk3P4skfICdC0VcZe/page/Ofwyqr_K-H",
    ],
    label: "Genomics",
    description: "Overview of genomics sequencing files and indexed variants",
    icon: GenomicsIcon,
    iconColor: "#651EFE",
  },
};

export default Report;
