import { useMatch } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Alert, Skeleton, Stack, Typography as T } from "@mui/material";
import { PlusIcon, CardsIcon } from "~/assets/icons";
import CardGrid from "~/components/CardGrid";
import useCohorts from "cohorts/hooks/useCohorts";
import CohortListCard from "cohorts/components/CohortListCard";
import CreateCohort from "cohorts/components/CreateCohort";

// TODO: refactor limit when working on pagination
const Skeletons = ({ limit = 20 }: { limit?: number }) => (
  <>
    {Array.from({ length: Math.min(limit, 8) }).map((_, i) => (
      <Skeleton key={i} variant="rounded" sx={{ height: 205, display: "flex" }} />
    ))}
  </>
);

const EmptyPlaceholder = () => {
  const showWelcome = !!useMatch("/cohorts");

  return (
    <Stack gap={3} alignItems="center">
      <CardsIcon />
      <Stack gap={1} alignItems="center">
        {showWelcome && <T variant="h6">Welcome to Cohort Explorer</T>}
        <T variant={showWelcome ? "body2" : "body1"}>To get started, create your first cohort.</T>
      </Stack>
      <CreateCohort
        callToAction={
          <LoadingButton startIcon={<PlusIcon />} variant="contained">
            New Cohort
          </LoadingButton>
        }
      />
    </Stack>
  );
};

const CohortList = ({ limit }: { limit?: number }) => {
  const { data: cohorts, isLoading, error } = useCohorts();
  // TODO: refactor below when backend support for pagination is completed
  const partialCohorts = cohorts?.slice(0, limit);
  const showEmpty = !error && !isLoading && cohorts?.length === 0;

  if (error) {
    return (
      <Alert severity="error" variant="outlined" sx={{ mb: 2 }} data-testid="cohort-list-error">
        Unable to load cohorts
      </Alert>
    );
  }

  if (showEmpty) {
    return <EmptyPlaceholder />;
  }

  return (
    <CardGrid>
      {isLoading ? (
        <Skeletons limit={limit} />
      ) : (
        partialCohorts?.map((cohort) => <CohortListCard key={cohort.id} cohort={cohort} />)
      )}
    </CardGrid>
  );
};

export default CohortList;
