import {
  GridView,
  ManageAccountsOutlined as ManageAccountsIcon,
  Menu,
  OpenInNew,
  People,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Typography as T,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon/SvgIcon";
import { ComponentProps, FunctionComponent, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import yn from "yn";
import {
  AirflowAppIcon,
  CampaignToolIcon,
  CohortExplorerAppIcon,
  DashboardAppIcon,
  FilesToolIcon,
  Home,
  ManifoldMonoBadgeIcon,
  ParticipantSearchToolIcon,
  SigmaAppIcon,
  SnowflakeAppIcon,
  SurveyToolIcon,
  TaskToolIcon,
  UploadToolIcon,
} from "~/assets/icons";
import { COLLAPSED_WIDTH } from "~/components/SideNav";
import useUser from "~/contexts/user";

interface NavItem {
  title: string;
  icon: typeof SvgIcon | FunctionComponent<ComponentProps<"svg">>;
  href: string;
  external?: boolean;
}

interface NavItemProps extends NavItem {
  closeDrawer: () => void;
}

const {
  MODE,
  VITE_CUSTOMER,
  VITE_RWB_ENABLED,
  VITE_SUPERSET_ENABLED,
  VITE_SM_ENABLED,
  VITE_ADMIN_APP_ENABLED,
  VITE_COHORT_EXPLORER_ENABLED,
  VITE_SIGMA_URL,
  VITE_SIGMA_WORKSPACE_ID,
  VITE_SM_LEGACY_SEGMENTS_ENABLED,
} = import.meta.env;

const NavItem = ({ closeDrawer, title, icon, href, external }: NavItemProps) => {
  const linkProps = external
    ? { component: "a", href, rel: "noreferrer", target: "_blank" }
    : { component: RouterLink, to: href };

  return (
    <ListItem disablePadding aria-label={`Navigate to ${title}`}>
      <Link
        {...linkProps}
        onClick={closeDrawer}
        underline="none"
        color="inherit"
        sx={{
          gap: 1.75,
          py: 1,
          px: 1.5,
          mb: 0.25,
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderRadius: 1.5,
          bgcolor: "transparent",
          userSelect: "none",
          "&:hover, &:active": {
            bgcolor: "rgba(255, 255, 255, 0.1)",
            "& svg, & p": {
              opacity: 0.99,
            },
          },
        }}
      >
        <Box
          component={icon}
          sx={{
            width: 22,
            height: 22,
            opacity: 0.96,
          }}
        />
        <T
          variant="body2"
          sx={{
            opacity: 0.96,
            color: "inherit",
            wordWrap: "break-word",
            py: 0.25,
          }}
        >
          {title}
        </T>
        {external && (
          <OpenInNew
            sx={{
              opacity: 0.55,
              ml: "auto",
              width: 16,
              height: 16,
            }}
          />
        )}
      </Link>
    </ListItem>
  );
};

interface NavSection {
  title?: string;
  children: NavItem[];
}

const useStudyMgmtSection = (permissions: string[]): NavSection | undefined => {
  const items = [];
  const showSm = yn(VITE_SM_ENABLED) && permissions.includes("view:sm-app");
  const showSegmentBuilder = !yn(VITE_SM_LEGACY_SEGMENTS_ENABLED);

  if (showSm) {
    items.push(
      {
        title: "Participants",
        href: "/sm/participants",
        icon: ParticipantSearchToolIcon,
      },
      { title: "Data Sync", href: "/sm/uploads", icon: UploadToolIcon },
      { title: "Survey Builder", href: "/sm/surveys", icon: SurveyToolIcon }
    );
    if (showSegmentBuilder) {
      items.push({ title: "Segment Builder", href: "/sm/segments", icon: ManageAccountsIcon });
    }
    items.push(
      { title: "Outreach", href: "/sm/outreach", icon: CampaignToolIcon },
      { title: "Tasks", href: "/sm/tasks", icon: TaskToolIcon }
    );
  }

  if (items.length) {
    return {
      title: "Study Management",
      children: items,
    };
  }
};

const useAnalysisSection = (permissions: string[]): NavSection | undefined => {
  const items = [];
  const showRwb = yn(VITE_RWB_ENABLED) && permissions.includes("view:workbench-app");
  const showDashboards =
    yn(VITE_SUPERSET_ENABLED) && permissions.includes("view:workbench-dashboards");

  if (showRwb) {
    if (showDashboards) {
      items.push({
        title: "Dashboards",
        href: "/dashboards",
        icon: DashboardAppIcon,
      });
    }

    items.push({
      title: "Workbenches",
      href: "/workbenches",
      icon: GridView,
    });

    if (yn(VITE_COHORT_EXPLORER_ENABLED) && permissions.includes("view:cohort-explorer-rows")) {
      items.push({
        title: "Cohort Explorer",
        href: "/cohorts",
        icon: CohortExplorerAppIcon,
      });
    }
  }

  if (items.length) {
    return {
      title: "Analysis",
      children: items,
    };
  }
};

const useAdminSection = (permissions: string[]): NavSection | undefined => {
  const items = [];
  const userMgmtEnabled = yn(VITE_ADMIN_APP_ENABLED) && permissions.includes("view:admin-app");

  if (userMgmtEnabled) {
    items.push({
      title: "User Management",
      href: "/admin/users",
      icon: People,
    });
  }

  if (VITE_SIGMA_WORKSPACE_ID && permissions.includes("view:sigma-ui")) {
    const url = `${VITE_SIGMA_URL}/manifold/f/${VITE_SIGMA_WORKSPACE_ID}`;

    items.push({
      title: "Sigma",
      href: url,
      icon: SigmaAppIcon,
      external: true,
    });
  }

  if (permissions.includes("user:airflow")) {
    const hostname = location.hostname?.split(".").slice(-4).join(".");

    items.push({
      title: "Airflow",
      href: `https://airflow.${hostname}`,
      icon: AirflowAppIcon,
      external: true,
    });
  }

  if (permissions.includes("view:snowflake-ui")) {
    const databaseName = MODE === "stage" ? `${VITE_CUSTOMER}_stage` : `${VITE_CUSTOMER}_prod`;

    items.push({
      title: "Snowflake",
      href: `https://app.snowflake.com/sciencecloud/${VITE_CUSTOMER}/#/data/databases/${databaseName.toUpperCase()}`,
      icon: SnowflakeAppIcon,
      external: true,
    });
  }

  if (items.length) {
    return {
      title: "Admin",
      children: items,
    };
  }
};

const useNavSections = (): NavSection[] => {
  const user = useUser();
  const permissions = user?.permissions ?? [];
  const studyMgmt = useStudyMgmtSection(permissions);
  const analysis = useAnalysisSection(permissions);
  const admin = useAdminSection(permissions);
  const defaultSection = {
    children: [
      { title: "Home", icon: Home, href: "/" },
      {
        title: "Data Browser",
        href: "/data-browser",
        icon: FilesToolIcon,
      },
    ],
  };
  const appSections = [studyMgmt, analysis, admin].filter(Boolean) as NavSection[];

  return [defaultSection, ...appSections];
};

const NavMenu = () => {
  const [open, setOpen] = useState(false);
  const navSections = useNavSections();
  const closeDrawer = () => setOpen(false);
  // Determine whether sections should be grouped with titles/sublist
  const splitSections = navSections.filter(({ title }) => title).length > 1;

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={`0 0 ${COLLAPSED_WIDTH}`}
      >
        <IconButton aria-label="Open main navigation" onClick={() => setOpen(true)}>
          <Menu fontSize="small" />
        </IconButton>
      </Box>
      <Drawer
        open={open}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            bgcolor: "#201547",
            width: 275,
            borderTopRightRadius: 15,
            color: "common.white",
          },
          elevation: 6,
        }}
        ModalProps={{
          slotProps: { backdrop: { sx: { backgroundColor: "rgba(0,0,0,0.2)" } } },
        }}
      >
        <Box display="flex" justifyContent="center" py={2}>
          <ManifoldMonoBadgeIcon width={24} />
        </Box>
        <Divider flexItem sx={{ borderColor: "white", opacity: 0.1, mx: 3 }} />
        <nav aria-label="Main navigation">
          <List sx={{ px: 1.5 }}>
            {navSections.flatMap((section) => {
              if (splitSections && section.title) {
                // Add additional list nesting if there are multiple section has title
                return (
                  <Stack component="li" key={section.title} pt={1}>
                    <T
                      variant="subtitle2"
                      pb={1}
                      px={1.5}
                      color="#A182FF"
                      letterSpacing={1}
                      fontSize={11}
                      textTransform="uppercase"
                    >
                      {section.title}
                    </T>
                    <List disablePadding>
                      {section.children.map((item) => (
                        <NavItem key={item.href} closeDrawer={closeDrawer} {...item} />
                      ))}
                    </List>
                  </Stack>
                );
              }

              // Return children without nesting
              return section.children.map((item) => (
                <NavItem key={item.href} closeDrawer={closeDrawer} {...item} />
              ));
            })}
          </List>
        </nav>
      </Drawer>
    </>
  );
};

export default NavMenu;
